import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    token: null,
    id: null,
    admin: false,
    error: null,
    loading: false,
    loadingRelog: false
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        id: action.id,
        admin: action.admin,
        error: null,
        loading: false,
        loadingRelog: false
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return updateObject(state, {error: null, loading: true});
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return updateObject(state, {error: action.error, loading: false, loadingRelog: false});
        case actionTypes.AUTH_ERROR_RESET:
            return updateObject(state, {error: null});
        case actionTypes.AUTH_LOGOUT:
            return updateObject(state, {token: null, admin: false});
        case actionTypes.AUTH_INIT_RELOG:
            return updateObject(state, {loadingRelog: true});
        default:
            return state;
    }
}

export default reducer;
