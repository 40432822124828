import React from "react";
import {
    AppBar,
    Toolbar,
    Dialog,
    Slide,
    Button,
    Typography
} from "@material-ui/core";
import classes from "./AdminDialog.module.css";
import AdminDialogContent from "./AdminDialogContent/AdminDialogContent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AdminDialog = (props) => {
    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
            <AppBar className={classes.AppBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.Title}>
                        Notifications
                    </Typography>
                    <Button color="inherit" onClick={props.onClose}>
                        Close
                    </Button>
                </Toolbar>
            </AppBar>
            <div className={classes.ContentTopMargin}>
                <h3 style={{marginLeft: "30px"}}>Coming Soon</h3>
            </div>
        </Dialog>
    );
}

export default AdminDialog;
