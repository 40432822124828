import * as actionTypes from "./actionTypes";
import * as actions from "../actions";
import axios from "axios";
import * as moment from "moment";

export const setGlobalLoading = (loading) => {
  return {
    type: actionTypes.GLOBAL_SET_LOADING,
    loading: loading
  }
}

export const setGlobalError = (text, severity) => {
  return {
    type: actionTypes.GLOBAL_ERROR_SET,
    visible: true,
    text: text,
    severity: severity,
  }
}

export const resetGlobalError = () => {
  return {
    type: actionTypes.GLOBAL_ERROR_RESET,
    visible: false,
  }
}

export const setGlobalSelectedDate = (date) => {
  return {
    type: actionTypes.GLOBAL_SET_SELECTED_DATE,
    month: moment(date).format("M"),
    year: moment(date).format("YYYY"),
  }
}
