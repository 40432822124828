import React from "react";
import {Paper, CircularProgress} from "@material-ui/core";
import classes from "./LoadingLarge.module.css";

const LoadingLarge = () => {
    return (
        <div className={classes.Root}>
            <Paper className={classes.Paper}>
                <p className={classes.LoadingText}>Loading ...</p>
                <div className={classes.LoadingContainer}>
                    <CircularProgress size={100}/>
                </div>
            </Paper>
        </div>
    );
}

export default LoadingLarge;
