import * as actionTypes from "./actionTypes";
import axios from "axios";
import {removeUser, updateUserSuccess} from "./user";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, id, isAdmin) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        id: id,
        admin: isAdmin
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    return dispatch => {
        dispatch(removeUser());
        dispatch({
            type: actionTypes.AUTH_LOGOUT
        });
    }
}

export const checkAuthTimeout = (expiresIn) => {
    return dispatch => {
        console.log('timeout', expiresIn);
        setTimeout(() => {
            console.log('timeout reached');
            dispatch(logout());
        }, expiresIn);
    };
}

export const initRelog = () => {
    return {
      type: actionTypes.AUTH_INIT_RELOG
    };
}

export const auth = (username, password, rememberMe) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password
        }
        axios.post('/user/login', authData)
            .then(response => {
                const expiresIn = response.data.expiresIn * 3600000;
                const expirationDate = new Date(new Date().getTime() + expiresIn);
                if (rememberMe) {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('expirationDate', expirationDate);
                    console.log('valid until: ', expirationDate);
                }
                dispatch(authSuccess(response.data.token, response.data.user.id, response.data.admin));
                dispatch(updateUserSuccess(response.data.user));
                dispatch(checkAuthTimeout(expiresIn));
            })
            .catch(err => {
                dispatch(authFail(err.response.data))
            });
    }

}

export const authErrorReset = () => {
    return {
        type: actionTypes.AUTH_ERROR_RESET
    };
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(initRelog());
                const id = localStorage.getItem('id');
                axios.defaults.headers.common['Authorization'] = "Bearer " + token;
                axios.get('/user?id=' + id)
                    .then(response => {
                        dispatch(authSuccess(token, id, response.data.admin));
                        dispatch(updateUserSuccess(response.data.user));
                        dispatch(checkAuthTimeout(expirationDate.getTime() - new Date().getTime()));
                    })
                    .catch(err => {
                        dispatch(authFail(err));
                        dispatch(logout());
                    });
            }
        }
    }
}
