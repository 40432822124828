import React from "react";
import {Calendar, Views, momentLocalizer, move} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "moment/locale/de";
import "react-big-calendar/lib/css/react-big-calendar.css";
import classes from "./CalendarScreen.module.css";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../store/actions";
import axios from "axios";
import ChangeEntryDialog from "./ChangeEntryDialog/ChangeEntryDialog";
import { getContrastYIQ, lightenColor } from "./ColorHelper";
import {EVENT_COLOR, TIME_INSTRUCTOR_CAN_CHANGE_ENTRY_IN_MINUTES} from "../../../contants";

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const DragAndDropCalendar = withDragAndDrop(Calendar);

const minTime = new Date();
minTime.setHours(7, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 30, 0);

const CalendarScreen = () => {
  const loc = momentLocalizer(moment);

  const [events, setEvents] = React.useState([]);
  const [selectedEvent, setSelectedEvent] = React.useState({});
  const [changeEventDialogOpen, setChangeEventDialogOpen] = React.useState(
    false
  );

  const dispatch = useDispatch();

  const currentUser = useSelector(store => store.user.user);

  React.useEffect(() => {
    loadWeek(new Date());
  }, []);

  const loadWeek = (dateInWeek) => {
    const from_date = moment(dateInWeek).startOf("week").format();
    const to_date = moment(dateInWeek).endOf("week").format();
    dispatch(actions.setGlobalLoading(true));
    dispatch(actions.setGlobalSelectedDate(from_date));

    axios
      .get("/calendar/specific", {
        params: {
          startDate: from_date,
          endDate: to_date,
        },
      })
      .then((response) => {
        // setEvents(response.data);
        let tmpyEvents = [];
        response.data.forEach((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          if (entry.instructor && entry.event) {
            entry.title = entry.title + " - " + entry.instructor.name;
          }
          // console.log(entry);
          tmpyEvents.push(entry);
        });
        setEvents(tmpyEvents);
        dispatch(actions.setGlobalLoading(false));
      })
      .catch((e) => {
        if (e.response) {
          dispatch(actions.setGlobalError(e.response.data.message, "error"));
          console.log(e.response);
        }
      });
  };

  const showChangeEventEntryDialog = (event) => {
    setSelectedEvent(event);
    setChangeEventDialogOpen(true);
  }

  const insertEvent = (event) => {
    const startFormatted = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
    const endFormatted = moment(event.end).format('YYYY-MM-DD HH:mm:ss')
    dispatch(actions.setGlobalLoading(true));
    axios
      .post("/calendar", {
        start: startFormatted,
        end: endFormatted,
      })
      .then((response) => {
        dispatch(actions.setGlobalLoading(false));
        loadWeek(event.start);
        dispatch(actions.setGlobalError(response.data, "info"));
      })
      .catch((e) => {
        dispatch(actions.setGlobalLoading(false));
        if (e.response) {
          dispatch(actions.setGlobalError(e.response.data.message, "error"));
          console.log(e.response);
        }
      });
  }

  const moveEvent = (event) => {
    if (event.event.event) { return; }

    if (!currentUser.admin) {

      if (!event.event.instructor) {
        return;
      } else if (event.event.instructor.id !== currentUser.id) {
        return;
      }

      let createdTime = moment(event.event.created_time);
      let durationSinceCreatedTime = moment.duration(moment(new Date()).diff(createdTime));
      let minutes = durationSinceCreatedTime.asMinutes();
      if (minutes > TIME_INSTRUCTOR_CAN_CHANGE_ENTRY_IN_MINUTES) {
        return;
      }
    }

    console.log("move event:", event);
    const startFormatted = moment(event.start).format('YYYY-MM-DD HH:mm:ss');
    const endFormatted = moment(event.end).format('YYYY-MM-DD HH:mm:ss')
    dispatch(actions.setGlobalLoading(true));
    axios
      .post("/calendar/update", {
        id: event.event.id,
        description: event.event.description,
        start: startFormatted,
        end: endFormatted,
      })
      .then((response) => {
        dispatch(actions.setGlobalLoading(false));
        loadWeek(event.start);
        dispatch(actions.setGlobalError(response.data, "info"));
      })
      .catch((e) => {
        dispatch(actions.setGlobalLoading(false));
        if (e.response) {
          dispatch(actions.setGlobalError(e.response.data.message, "error"));
          console.log(e.response);
        }
      });
  }

  return (
    <div className={classes.container}>
      <ChangeEntryDialog
        onReloadCurrent={loadWeek}
        event={selectedEvent}
        open={changeEventDialogOpen}
        onClose={() => setChangeEventDialogOpen(false)}
      />
      <DragAndDropCalendar
        onNavigate={(date) => {
          loadWeek(date);
        }}
        selectable
        localizer={loc}
        events={events}
        defaultView={Views.WEEK}
        defaultDate={new Date(moment())}
        onSelectEvent={(event) => {
          if (currentUser.admin) {
            showChangeEventEntryDialog(event);
            return;
          }

          if (event.instructor && !event.event) {
            if (currentUser.id === event.instructor.id) {
              let createdTime = moment(event.created_time);
              let durationSinceCreatedTime = moment.duration(moment(new Date()).diff(createdTime));
              let minutes = durationSinceCreatedTime.asMinutes();

              if (minutes < TIME_INSTRUCTOR_CAN_CHANGE_ENTRY_IN_MINUTES) {
                showChangeEventEntryDialog(event);
              } else {
                dispatch(actions.setGlobalError("You cant change this anymore!", "warning"));
              }
            } else {
              dispatch(actions.setGlobalError("This is not your entry!", "warning"));
            }
          } else {
            dispatch(actions.setGlobalError("You cant change events!", "warning"));
          }

        }}
        views={["week"]}
        style={{ height: 800 }}
        scrollToTime={minTime}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "#3174AD",
            borderColor: "grey",
            color: "white",
          };

          if (event.color) {
            newStyle.backgroundColor = event.color;
            newStyle.color = getContrastYIQ(event.color);
            // newStyle.borderColor = event.color;
          }

          if (event.event) {
            newStyle.backgroundColor = EVENT_COLOR;
            newStyle.color = getContrastYIQ(EVENT_COLOR);
            newStyle.borderColor = "black";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}
        onEventDrop={moveEvent}
        onEventResize={moveEvent}
        onSelectSlot={insertEvent}
      />
    </div>
  );
};

export default CalendarScreen;
