import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    user: null,
    error: null,
    loading: false
};

const reducer = (state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.USER_UPDATE_START:
            return updateObject(state, {error: null, loading: true});
        case actionTypes.USER_UPDATE_SUCCESS:
            return updateObject(state, {loading: false, user: action.user});
        case actionTypes.USER_UPDATE_SUCCESS_NO_RESPONSE:
            return updateObject(state, {loading: false});
        case actionTypes.USER_UDPATE_FAIL:
            return updateObject(state, {error: action.error, loading: false});
        case actionTypes.USER_REMOVE:
            return updateObject(state, {user: null});
        default:
            return state;
    }
}

export default reducer;
