import React, { Component } from "react";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Fab,
    IconButton
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import classes from "./AdminDialogUsers.module.css";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "username", label: "Benutzername", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "user-group", label: "Benutzergruppe", minWidth: 170 },
  { id: "actions", label: "", minWidth: 50 },
];

class AdminDialogUsers extends Component {
  state = {
    users: [],
    page: 0,
    rowsPerPage: 10,
  };

  componentDidMount() {
    axios
      .get("/user/list")
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((err) => {});
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: +event.target.value,
    });
  };

  render() {
    return (
        <div>
          <Fab color="primary" className={classes.AddFab} disabled>
            <AddIcon />
          </Fab>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs>
              <Paper>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.users
                          .slice(
                              this.state.page * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage
                          )
                          .map((user) => {
                            return (
                                <TableRow hover key={user.id}>
                                  <TableCell>{user.name}</TableCell>
                                  <TableCell>{user.username}</TableCell>
                                  <TableCell>{user.email}</TableCell>
                                  <TableCell>WIP</TableCell>
                                  <TableCell>
                                    <IconButton disabled><EditIcon /></IconButton>
                                  </TableCell>
                                </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={this.state.users.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </div>
    );
  }
}

export default AdminDialogUsers;
