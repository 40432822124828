import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import * as moment from "moment";

const initialState = {
  loading: false,
  errorVisible: false,
  errorText: "",
  errorSeverity: "error",
  selectedMonth: moment().format("M"),
  selectedYear: moment().format("YYYY"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_SET_LOADING:
      return updateObject(state, { loading: action.loading });
    case actionTypes.GLOBAL_ERROR_RESET:
      return updateObject(state, { errorVisible: false });
    case actionTypes.GLOBAL_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedMonth: action.month,
        selectedYear: action.year,
      });
    case actionTypes.GLOBAL_ERROR_SET:
      return updateObject(state, {
        errorVisible: action.visible,
        errorText: action.text,
        errorSeverity: action.severity,
        loading: false,
      });
    default:
      return state;
  }
};

export default reducer;
