// AUTH
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_ERROR_RESET = "AUTH_ERROR_RESET";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_INIT_RELOG = "AUTH_INIT_RELOG";

// USER
export const USER_UPDATE_START = "USER_UPDATE_START";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_SUCCESS_NO_RESPONSE =
  "USER_UPDATE_SUCCESS_NO_RESPONSE";
export const USER_UDPATE_FAIL = "USER_UPDATE_FAIL";
export const USER_REMOVE = "USER_REMOVE";

// GLOBAL
export const GLOBAL_SET_LOADING = "GLOBAL_SET_LOADING";
export const GLOBAL_ERROR_SET = "GLOBAL_ERROR_SET";
export const GLOBAL_ERROR_RESET = "GLOBAL_ERROR_RESET";
export const GLOBAL_SET_SELECTED_DATE = "GLOBAL_SET_SELECTED_DATE";
