import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import AdminDialog from "../Content/Administration/AdminDialog";

import NotificationsIcon from "@material-ui/icons/Notifications";
import LoopIcon from "@material-ui/icons/Loop";
import AddIcon from "@material-ui/icons/Add";

import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";

import * as actions from "../../store/actions";

import axios from "axios";
import ChangeEntryDialog from "../Content/Calendar/ChangeEntryDialog/ChangeEntryDialog";
import SelectDateDialog from "./SelectDateDialog/SelectDateDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    // display: 'none',
    textAlign: "left",
    cursor: "pointer",
    // [theme.breakpoints.up('sm')]: {
    //     display: 'block',
    // },
  },
  navbar: {
    flexGrow: 1,
    marginLeft: theme.spacing(4),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  notificationsButton: {
    marginLeft: theme.spacing(2),
    textTransform: "none",
    fontSize: "16px",
  },
  accountButton: {
    marginLeft: theme.spacing(2),
    textTransform: "lowercase",
    fontSize: "16px",
  },
  colorArea: {
    backgroundColor: "red",
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
  progress: {
    marginLeft: "20px",
    color: "white",
  },
}));

const Header = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminDialogOpen, setAdminDialogOpen] = React.useState(false);

  const currentUser = useSelector((store) => store.user.user);
  const globalLoading = useSelector((store) => store.global.loading);

  const [createEventDialogOpen, setCreateEventDialogOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onTitleClick = () => {
    props.history.push("/calendar");
  };

  const onLogoutClick = () => {
    handleClose();
    props.onLogout();
  };

  const onAdminClick = () => {
    handleClose();
    // props.history.push("/administration");
    setAdminDialogOpen(true);
  };

  let userDisplayName = "Benutzer";
  if (props.user) {
    userDisplayName = props.user.username;
  }

  let adminItem = null;
  if (props.isAdmin) {
    adminItem = <MenuItem onClick={onAdminClick}>Administration</MenuItem>;
  }

  const onCloseAdminDialog = () => {
    setAdminDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <AdminDialog open={adminDialogOpen} onClose={onCloseAdminDialog} />

      <SelectDateDialog
        open={createEventDialogOpen}
        onClose={() => setCreateEventDialogOpen(false)}
      />

      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={onTitleClick}
          >
            AST Instructor
          </Typography>

          {props.isAdmin ? (
            <Button
              color="inherit"
              className={classes.notificationsButton}
              onClick={onAdminClick}
            >
              <NotificationsIcon />
              Notifications
            </Button>
          ) : null}
          {globalLoading ? (
            <CircularProgress className={classes.progress} color="secondary" />
          ) : null}
          <div className={classes.navbar}></div>
          {props.user ? (
            <div style={{ marginRight: "20px", color: "#bfbfbf" }}>
              {props.user.comment}
            </div>
          ) : null}
          {props.isAdmin ? (
            <>
              <Button
                color="inherit"
                className={classes.notificationsButton}
                onClick={() => {
                  setCreateEventDialogOpen(true);
                }}
              >
                Create Event
                <AddIcon />
              </Button>
              <Button
                color="inherit"
                className={classes.notificationsButton}
                onClick={() => {
                  window.open(
                    "http://internal.flugsimulator-karlsruhe.de/internals/generateBlocks",
                    "_blank"
                  );
                  // dispatch(actions.setGlobalLoading(true));
                  // axios
                  //   .get(
                  //     "http://internal.flugsimulator-karlsruhe.de/internals/generateBlocks"
                  //   )
                  //   .then((data) => {
                  //     dispatch(actions.setGlobalLoading(false));
                  //     alert(data);
                  //   })
                  //   .catch((error) => {
                  //     dispatch(actions.setGlobalLoading(false));
                  //     alert(error);
                  //   });
                }}
              >
                Regenerate
                <LoopIcon />
              </Button>
            </>
          ) : null}
          <Button
            color="inherit"
            className={classes.accountButton}
            onClick={handleClick}
          >
            {userDisplayName}
            <div
              className={classes.colorArea}
              style={
                currentUser ? { backgroundColor: "#" + currentUser.color } : null
              }
            ></div>
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/*<MenuItem onClick={onAccountClick}>Mein Profil</MenuItem>*/}
            {/*{adminItem}*/}
            <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Header);
