import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction
} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight";

const menuItems = [
    {title: 'Benutzer', value: 'users'},
    {title: 'Benutzergruppen', value: 'user-groups'},
    {title: 'Einheiten', value: 'units'},
];

const AdminDialogNav = (props) => {

    const onNavigate = (value) => {
        props.setNav(value);
    }

    const list = menuItems.map(entry => {
       return (
            <ListItem key={entry.value} button onClick={() => onNavigate(entry)}>
                <ListItemText primary={entry.title} />
                <ListItemSecondaryAction>
                    <RightArrowIcon />
                </ListItemSecondaryAction>
            </ListItem>
       );
    });

    return (
        <List>
            {list}
        </List>
    );
}

export default AdminDialogNav;
