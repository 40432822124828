import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import AdminDialogNav from "./AdminDialogNav/AdminDialogNav";
import AdminDialogUnits from "./AdminDialogUnits/AdminDialogUnits";
import * as actions from "../../../../store/actions/index";
import AdminDialogUsers from "./AdminDialogUsers/AdminDialogUsers";
import AdminDialogUserGroups from "./AdminDialogUserGroups/AdminDialogUserGroups";
import classes from "./AdminDialogContent.module.css";

class AdminDialogContent extends Component {
  state = {
    activeNav: {
      title: "Benutzer",
      value: "users"
    },
  };

  render() {
    let rightSide;

    switch (this.state.activeNav.value) {
      case "users":
        rightSide = <AdminDialogUsers />;
        break;
      case "user-groups":
        rightSide = <AdminDialogUserGroups />;
        break;
      case "units":
        rightSide = (
          <AdminDialogUnits
            onLoadUnits={this.props.loadUnits}
            onAddUnit={this.props.addUnit}
            onUpdateUnit={this.props.updateUnit}
            onDeleteUnit={this.props.deleteUnit}
            units={this.props.units}
          />
        );
        break;
      default:
        rightSide = null;
    }

    return (
      <Grid container>
        <Grid item md={3}>
          <AdminDialogNav
            setNav={(value) => this.setState({ activeNav: value })}
          />
        </Grid>
        <Grid item md={9}>
          <p className={classes.title}>{this.state.activeNav.title}</p>
          {rightSide}
        </Grid>
      </Grid>
    );
  }
}

export default AdminDialogContent;
