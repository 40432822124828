import React from "react";
import classes from "./SelectDateDialogContent.module.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";

const SelectDateDialogContent = (props) => {
  const handleMonthChange = (event) => {
    props.setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    props.setSelectedYear(event.target.value);
  };

  const numOfYearsAheadSelectable = 3;
  let years = new Array();
  const selectedYearInt = parseInt(moment().format("YYYY"));
  for (let i = 0; i < numOfYearsAheadSelectable + 1; i++) {
    years.push(selectedYearInt + i);
  }

  let yearItems = years.map((year) => {
    return <MenuItem value={year}>{year}</MenuItem>;
  });

  return (
    <div className={classes.selectContainer}>
      <Select
        className={classes.selectView}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.selectedMonth}
        onChange={handleMonthChange}
      >
        <MenuItem value={1}>Januar</MenuItem>
        <MenuItem value={2}>Februar</MenuItem>
        <MenuItem value={3}>März</MenuItem>
        <MenuItem value={4}>April</MenuItem>
        <MenuItem value={5}>Mai</MenuItem>
        <MenuItem value={6}>Juni</MenuItem>
        <MenuItem value={7}>Juli</MenuItem>
        <MenuItem value={8}>August</MenuItem>
        <MenuItem value={9}>September</MenuItem>
        <MenuItem value={10}>Oktober</MenuItem>
        <MenuItem value={11}>November</MenuItem>
        <MenuItem value={12}>Dezember</MenuItem>
      </Select>

      <Select
        className={classes.selectView}
        style={{ marginLeft: "10px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.selectedYear}
        onChange={handleYearChange}
      >
        {yearItems}
      </Select>
    </div>
  );
};

export default SelectDateDialogContent;
