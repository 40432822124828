import React, {Component} from 'react';
import classes from './App.module.css';
import Layout from "./components/Layout/Layout";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from "./components/Auth/Auth";
import {connect} from "react-redux";
import * as actions from "./store/actions/index";
import Logout from "./components/Auth/Logout/Logout";
import packageJson from '../package.json';

class App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup();
    }

    render() {
        return (
            <BrowserRouter className={classes.Root}>
                <div className="App">
                    <Switch>
                        <Route path="/auth" component={Auth} />
                        <Route path="/logout" component={Logout} />
                        <Layout />
                    </Switch>
                </div>
                <div style={{position: "absolute", right: 0, bottom: 0, marginRight: "10px", marginBottom: "10px", color: "grey"}}>{packageJson.version}</div>
            </BrowserRouter>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
}

export default connect(null, mapDispatchToProps)(App);
