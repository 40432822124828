import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  withStyles,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ChangeEntryDialogContent from "./ChangeEntryDialogContent";
import { getContrastYIQ, lightenColor } from "../ColorHelper";
import Draggable from "react-draggable";
import { EVENT_COLOR } from "../../../../contants";
import axios from "axios";
import * as actions from "../../../../store/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const ChangeEntryDialog = withStyles(styles)((props) => {
  let titleStyle = {
    cursor: "move",
    backgroundColor: "#3174AD",
    color: "white",
  };

  const [contentSelectedStart, setContentSelectedStart] = React.useState(null);
  const [contentSelectedEnd, setContentSelectedEnd] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [contentDetailedData, setContentDetailedData] = React.useState({});

  React.useEffect(() => {

    if (props.event.event) {
      setLoading(true);
      axios
        .get("/session-details/" + props.event.event_id)
        .then((response) => {
          setLoading(false);
          setContentDetailedData(response.data);
          console.log(response)
        })
        .catch((e) => {
          setLoading(false);
          if (e.response) {
            dispatch(actions.setGlobalError(e.response.data.message, "error"));
            console.log(e.response);
          }
        });
    }

    const startFormatted = moment(props.event.start).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const endFormatted = moment(props.event.end).format("YYYY-MM-DD HH:mm:ss");
    setContentSelectedStart(startFormatted);
    setContentSelectedEnd(endFormatted);
  }, [props.event]);

  if (!props.event.event && props.event.color) {
    titleStyle.backgroundColor = props.event.color;
    titleStyle.color = getContrastYIQ(props.event.color);
  } else if (props.event.event) {
    // event
    // const lightenedColor = lightenColor(props.event.instructor.color, 40)
    titleStyle.backgroundColor = EVENT_COLOR;
    titleStyle.color = getContrastYIQ(EVENT_COLOR);
    titleStyle.borderColor = EVENT_COLOR;
  }

  let closeButtonStyle = {
    color: "white",
  };

  if (props.event.color) {
    closeButtonStyle.color = getContrastYIQ(props.event.color);
  } else if (props.event.event && props.event.instructor) {
    const lightenedColor = lightenColor(props.event.instructor.color, 40);
    closeButtonStyle.color = getContrastYIQ(lightenedColor);
  }

  let forceActionsDisabled = false;
  let forceContentDisabled = false;

  if (props.event.event) {
    forceActionsDisabled = true;
    forceContentDisabled = true;
  }

  const dispatch = useDispatch();

  const onDeleteEvent = () => {
    // dispatch(actions.setGlobalLoading(true));
    setLoading(true);
    axios
      .delete("/calendar?id=" + props.event.id)
      .then((response) => {
        // dispatch(actions.setGlobalLoading(false));
        setLoading(false);
        props.onReloadCurrent(props.event.start);
        props.onClose();
        dispatch(actions.setGlobalError(response.data, "info"));
      })
      .catch((e) => {
        // dispatch(actions.setGlobalLoading(false));
        setLoading(false);
        if (e.response) {
          dispatch(actions.setGlobalError(e.response.data.message, "error"));
          console.log(e.response);
        }
      });
  };

  const onSave = () => {
    setLoading(true);
    // dispatch(actions.setGlobalLoading(true));
    axios
      .post("/calendar/update", {
        id: props.event.id,
        description: props.event.description,
        start: contentSelectedStart,
        end: contentSelectedEnd,
      })
      .then((response) => {
        setLoading(false);
        // dispatch(actions.setGlobalLoading(false));
        props.onReloadCurrent(props.event.start);
        props.onClose();
        dispatch(actions.setGlobalError(response.data, "info"));
      })
      .catch((e) => {
        setLoading(false);
        // dispatch(actions.setGlobalLoading(false));
        if (e.response) {
          dispatch(actions.setGlobalError(e.response.data.message, "error"));
          console.log(e.response);
        }
      });
  };

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      PaperComponent={PaperComponent}
    >
      {/*{loading ? <LinearProgress />: null}*/}
      <DialogTitle
        id="draggable-dialog-title"
        disableTypography
        onClose={props.onClose}
        className={props.classes.root}
        style={titleStyle}
      >
        <Typography variant="h6">{props.event.title}</Typography>
        {props.onClose ? (
          <IconButton
            aria-label="close"
            className={props.classes.closeButton}
            onClick={props.onClose}
            style={closeButtonStyle}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <ChangeEntryDialogContent
          event={props.event}
          notChangeable={forceContentDisabled}
          setContentSelectedEnd={setContentSelectedEnd}
          setContentSelectedStart={setContentSelectedStart}
          additionalData={contentDetailedData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onDeleteEvent}
          color="secondary"
          disabled={forceActionsDisabled}
        >
          Remove
        </Button>
        <Button
          autoFocus
          onClick={onSave}
          color="primary"
          disabled={forceActionsDisabled}
        >
          Save changes
        </Button>
      </DialogActions>
      {loading ? <LinearProgress />: null}
    </Dialog>
  );
});

export default ChangeEntryDialog;
