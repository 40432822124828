import React, {useState, useEffect} from "react";
import {
    TextField,
    Button
} from "@material-ui/core";
import classes from "./AdminDialogUnitsEdit.module.css";

const AdminDialogUnitsEdit = props => {

    const [value, setValue] = useState('');
    const [id, setID] = useState(undefined);

    useEffect(() => {
        if (props.unit) {
            setValue(props.unit.title);
            setID(props.unit.id);
        } else {
            setID(undefined);
        }
    }, [props.unit]);

    const onInput = event => {
        setValue(event.target.value);
    }

    const onAdd = () => {
        if (value !== '') {
            props.onAddUnit({title: value});
            setValue('');
        }
    }

    const onUpdate = () => {
        if (value !== '') {

        }
    }

    const onDelete = () => {
        if (id !== undefined) {
            props.onDeleteUnit(id);
            setValue('');
        }
    }

    let buttons = (
        <Button className={classes.LeftMargin} onClick={onAdd}>Hinzufügen</Button>
    );

    if (props.unit) {
        buttons = (
            <div>
                <Button className={classes.LeftMargin} onClick={onUpdate} disabled>Speichern</Button>
                <Button className={classes.LeftMargin} onClick={onDelete}>Löschen</Button>
            </div>
        );
    }

    return (
        <div className={classes.FlexContainer}>
            <TextField label="Einheit" variant="outlined" value={value} onChange={onInput} />
            {buttons}
        </div>
    );
}

export default AdminDialogUnitsEdit;
