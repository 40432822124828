import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import classes from "./AdminDialogUnitsList.module.css";

const AdminDialogUnitsList = props => {

    const tableContent = props.units.map(unit => {
        return (
            <TableRow key={unit.id}>
                <TableCell>{unit.title}</TableCell>
                <TableCell>
                    <IconButton onClick={() => props.onEditClick(unit)}><EditIcon /></IconButton>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <TableContainer component={Paper} className={classes.Table}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.TableHeader}>Einheiten</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableContent}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AdminDialogUnitsList;
