import React from "react";
import ReactDOM from "react-dom";

import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";

import authReducer from "./store/reducers/auth";
import userReducer from "./store/reducers/user";
import globalReducer from "./store/reducers/global";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  global: globalReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// axios.defaults.baseURL = 'http://192.168.178.37:4500';
axios.defaults.baseURL = "https://api.instructor.hangar-sued.de";
// axios.defaults.baseURL = "http://192.168.1.182:4500";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] = "";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
