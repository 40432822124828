import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Typography,
  withStyles,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import * as actions from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SelectDateDialogContent from "./SelectDateDialogContent";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const styles = (theme) => ({
  root: {
    cursor: "move",
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const SelectDateDialog = withStyles(styles)((props) => {
  let titleStyle = {
    backgroundColor: "#3174AD",
    color: "white",
  };

  const sourceSelectedMonth = useSelector(
    (store) => store.global.selectedMonth
  );
  const sourceSelectedYear = useSelector((store) => store.global.selectedYear);

  const [selectedMonth, setSelectedMonth] = React.useState(0);
  const [selectedYear, setSelectedYear] = React.useState(2020);

  React.useEffect(() => {
    setSelectedMonth(sourceSelectedMonth);
    setSelectedYear(sourceSelectedYear);
  }, [sourceSelectedMonth, sourceSelectedYear]);

  const dispatch = useDispatch();

  const onSave = () => {
    props.onClose();
    window.open(
      "https://internal.flugsimulator-karlsruhe.de/booking/index/" +
        selectedMonth +
        "/" +
        selectedYear +
        "/1",
      "_blank"
    );
  };

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      PaperComponent={PaperComponent}
    >
      <DialogTitle
        id="draggable-dialog-title"
        disableTypography
        onClose={props.onClose}
        className={props.classes.root}
        style={titleStyle}
      >
        <Typography variant="h6">Select Timespan</Typography>
        {props.onClose ? (
          <IconButton
            aria-label="close"
            className={props.classes.closeButton}
            onClick={props.onClose}
            style={{color: "white"}}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        <SelectDateDialogContent
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onSave}
          color="primary"
          // disabled={forceActionsDisabled}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SelectDateDialog;
