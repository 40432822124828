import * as actionTypes from "./actionTypes";
import axios from "axios";

export const updateStart = () => {
    return {
        type: actionTypes.USER_UPDATE_START
    }
}

export const updateUserSuccess = (user) => {
    return {
        type: actionTypes.USER_UPDATE_SUCCESS,
        user: user
    }
}

export const removeUser = () => {
    return {
        type: actionTypes.USER_REMOVE
    }
}

export const updateFail = (err) => {
    return {
        type: actionTypes.USER_UDPATE_FAIL,
        error: err
    }
}

export const updateUser = (updatedUser) => {
    return dispatch => {
        dispatch(updateStart());
        axios.post('/user/update', updatedUser)
            .then(response => {
                dispatch(updateUserSuccess(response.data));
            })
            .catch(err => {
                dispatch(updateFail(err));
            });
    };
}

export const updateUserPassword = (id, oldPassword, newPassword) => {
    return dispatch => {
      dispatch(updateStart());
      const data = {
          id: id,
          old: oldPassword,
          new: newPassword
      }
        axios.post('/user/update/password', data)
            .then(response => {
                dispatch({
                    type: actionTypes.USER_UPDATE_SUCCESS_NO_RESPONSE
                });
            })
            .catch(err => {
                dispatch(updateFail(err));
            });
    };
}
