import React, {Component} from "react";
import Header from "../Header/Header";
import {Route, withRouter, Switch, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";

import classes from "./Layout.module.css";
import CalendarScreen from "../Content/Calendar/CalendarScreen";

class Layout extends Component{

    state = {
        activeNav: -1
    }

    navItemSelected = (index, route) => {
        this.setState({activeNav: index});
        this.props.history.push(route);
    }

    render() {
        let routes = (
            <Switch>
                <Redirect to="/auth" />
            </Switch>
        );

        if (this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/calendar" component={CalendarScreen}/>
                    <Redirect from="/" to="/" />
                </Switch>
            );
        }

        return (
            <div className={classes.Root}>
                <Header
                    activeNav={this.state.activeNav}
                    onNavClicked={this.navItemSelected}
                    user={this.props.user}
                    isAdmin={this.props.isAdmin}
                    onLogout={this.props.onLogout}
                />
                {routes}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        user: state.user.user,
        isAdmin: state.auth.admin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
