import React, { Component } from "react";
import axios from 'axios';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    Fab
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import classes from "./AdminDialogUserGroups.module.css";
import AddIcon from "@material-ui/icons/Add";

const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "is-admin", label: "Admin?", minWidth: 170 },
    { id: "wip", label: "WIP", minWidth: 170 },
    { id: "actions", label: "", minWidth: 50 },
];

class AdminDialogUserGroups extends Component {

    state = {
        user_groups: [],
        page: 0,
        rowsPerPage: 10,
    };

    componentDidMount() {
        axios.get("/groups")
            .then(response => {
                console.log(response);
                this.setState({user_groups: response.data});
                console.log(this.state.user_groups);
            })
            .catch(error => {

            });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value,
        });
    };

    render() {
        return (
            <div>
                <Fab color="primary" className={classes.AddFab} disabled>
                    <AddIcon />
                </Fab>
                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs>
                        <Paper>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.user_groups
                                            .slice(
                                                this.state.page * this.state.rowsPerPage,
                                                this.state.page * this.state.rowsPerPage +
                                                this.state.rowsPerPage
                                            )
                                            .map((user_group) => {
                                                return (
                                                    <TableRow hover key={user_group.id}>
                                                        <TableCell>{user_group.name}</TableCell>
                                                        <TableCell>{user_group.admin? 'true': 'false'}</TableCell>
                                                        <TableCell>WIP</TableCell>
                                                        <TableCell>
                                                            <IconButton disabled><EditIcon /></IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={this.state.user_groups.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </div>
        );
    }
};

export default AdminDialogUserGroups;
