import React, { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import AdminDialogUnitsList from "./AdminDialogUnitsList/AdminDialogUnitsList";
import AdminDialogUnitsEdit from "./AdminDialogUnitsEdit/AdminDialogUnitsEdit";

const AdminDialogUnits = (props) => {
  const [editUnit, setEditUnit] = useState(null);

  useEffect(() => {
    props.onLoadUnits();
  }, []);

  const onEditClick = (unit) => {
    setEditUnit(unit);
    console.log(editUnit);
  };

  const onUpdate = (unit) => {
    props.onUpdateUnit(unit);
    setEditUnit(null);
  };

  const onDelete = (id) => {
    props.onDeleteUnit(id);
    setEditUnit(null);
  };

  return (
    <Grid container>
      <Grid item xs>
        <AdminDialogUnitsEdit
          onAddUnit={props.onAddUnit}
          onUpdateUnit={onUpdate}
          onDeleteUnit={onDelete}
          unit={editUnit}
        />
      </Grid>
      <Grid item xs>
        <AdminDialogUnitsList units={props.units} onEditClick={onEditClick} />
      </Grid>
    </Grid>
  );
};

export default AdminDialogUnits;
