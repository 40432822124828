import React from "react";
import classes from "./ChangeEntryDialogContent.module.css";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Grid } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

const ChangeEntryDialogContent = (props) => {
  const [isEvent, setIsEvent] = React.useState(false);
  const [internalNote, setInternalNote] = React.useState("");
  const [customerNote, setCustomerNote] = React.useState("");
  const [date, setDate] = React.useState(new Date());
  const [fromTime, setFromTime] = React.useState(new Date());
  const [toTime, setToTime] = React.useState(new Date());

  const currentUser = useSelector((store) => store.user.user);

  React.useEffect(() => {
    setIsEvent(props.event.event);

    setInternalNote(props.additionalData.internalNote);
    setCustomerNote(props.additionalData.customerNote);

    setDate(new Date(props.event.start));
    setFromTime(new Date(props.event.start));
    setToTime(new Date(props.event.end));
  }, []);

  const handleCustomerNoteChange = (customerNote) => {
    console.log(customerNote.target.value);
    setCustomerNote(customerNote.target.value);
  };

  const handleInternalNoteChange = (internalNote) => {
    console.log(internalNote.target.value);
    setInternalNote(internalNote.target.value);
  };

  const handleDateChange = (dateTmpy) => {
    setDate(dateTmpy);
    props.setContentSelectedStart(formatDate(dateTmpy, fromTime));
    props.setContentSelectedEnd(formatDate(dateTmpy, toTime));
  };

  const handleFromTimeChange = (fromTimeTmpy) => {
    setFromTime(fromTimeTmpy);
    props.setContentSelectedStart(formatDate(date, fromTimeTmpy));
  };

  const handleToTimeChange = (toTimeTmpy) => {
    setToTime(toTimeTmpy);
    props.setContentSelectedEnd(formatDate(date, toTimeTmpy));
  };

  const formatDate = (date, time) => {
    const dateString = moment(date).format("YYYY-MM-DD");
    const timeString = moment(time).format("HH:mm:ss");
    return dateString + " " + timeString;
  };

  return (
    <>
      {isEvent ? (
        <>
          <TextField
            className={classes.internalCommentField}
            style={{marginBottom: "10px"}}
            label="Customer Note"
            multiline
            rows={5}
            value={customerNote}
            onChange={handleCustomerNoteChange}
            variant="filled"
            disabled={!currentUser.admin}
          />
          <TextField
            className={classes.internalCommentField}
            label="Internal Note"
            multiline
            rows={8}
            disabled
            value={internalNote}
            onChange={handleInternalNoteChange}
            variant="filled"
            disabled={!currentUser.admin}
          />
        </>
      ) : null}
      <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            disabled={props.notChangeable}
            disableToolbar
            variant="inline"
            format="dd DD.MM.yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Date"
            autoOk
            value={date}
            onChange={handleDateChange}
            // minDate={new Date()}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <Grid container justify="space-between">
            <KeyboardTimePicker
              disabled={props.notChangeable}
              margin="normal"
              id="time-picker-from"
              label="Start"
              ampm={false}
              minutesStep={30}
              autoOk
              value={fromTime}
              onChange={handleFromTimeChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
            <KeyboardTimePicker
              disabled={props.notChangeable}
              margin="normal"
              id="time-picker-to"
              label="End"
              ampm={false}
              minutesStep={30}
              autoOk
              value={toTime}
              onChange={handleToTimeChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};

export default ChangeEntryDialogContent;
