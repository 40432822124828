import React, { Component } from "react";
import {
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import classes from "./Auth.module.css";
import { Redirect } from "react-router-dom";

import * as actions from "../../store/actions/index";
import { isValidEmail } from "../../validator/validator";
import LoadingLarge from "../../shared/LoadingLarge/LoadingLarge";

class Auth extends Component {
  state = {
    formIsValid: false,
    rememberMe: true,
    controls: {
      username: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
      password: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        touched: false,
      },
    },
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(
      this.state.controls.username.value,
      this.state.controls.password.value,
      this.state.rememberMe
    );
  };

  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email && value.trim() !== "") {
      isValid = isValidEmail(value) && isValid;
    }

    return isValid;
  }

  onInputHandle = (event) => {
    const updatedLoginForm = {
      ...this.state.controls,
    };
    const updatedFormElement = {
      ...updatedLoginForm[event.target.name],
    };
    updatedFormElement.touched = true;
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedLoginForm[event.target.name] = updatedFormElement;

    let formIsValid = true;
    for (let inputIdent in updatedLoginForm) {
      formIsValid = updatedLoginForm[inputIdent].valid && formIsValid;
    }
    this.setState({ controls: updatedLoginForm, formIsValid: formIsValid });
  };

  onToggleLogin = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  render() {
    let errorMsg = null;

    if (this.props.error) {
      errorMsg = (
        <Alert
          severity="error"
          className={classes.LoginError}
          onClose={this.props.onErrorReset}
        >{this.props.error.error}
        </Alert>
      );
    }

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to="/calendar" />;
    }

    let content = <LoadingLarge />;

    if (!this.props.loadingRelog) {
      content = (
        <>
          {authRedirect}
          <img
            className={classes.Logo}
            src={process.env.PUBLIC_URL + "/images/hangar-sued.png"}
            alt="Hangar Sued Logo"
          />
          <div className={classes.LoginForm}>
            <p className={classes.LoginHeader}>Login</p>
            {errorMsg}
            <form>
              <TextField
                onChange={this.onInputHandle}
                name="username"
                label="Username"
                variant="outlined"
                disabled={this.props.loading}
                error={
                  !this.state.controls.username.valid &&
                  this.state.controls.username.touched
                }
                className={classes.Input}
              />
              <TextField
                onChange={this.onInputHandle}
                name="password"
                label="Password"
                variant="outlined"
                type="password"
                disabled={this.props.loading}
                error={
                  !this.state.controls.password.valid &&
                  this.state.controls.password.touched
                }
                className={classes.Input}
              />
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.rememberMe}
                        onChange={this.onToggleLogin}
                        disabled={this.props.loading}
                      />
                    }
                    label="remember me"
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!this.state.formIsValid || this.props.loading}
                    onClick={this.onSubmitHandler}
                  >
                    Login
                  </Button>
                  {this.props.loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
        </>
      );
    }

    return <div className={classes.Root}>{content}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    loadingRelog: state.auth.loadingRelog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password, rememberMe) =>
      dispatch(actions.auth(username, password, rememberMe)),
    onErrorReset: () => dispatch(actions.authErrorReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
